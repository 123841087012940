import {
    ColourData,
    FeatureList,
    Hero,
    TitleBlock,
    CTA,
    RichText
}                             from '@open-law/open-law-shared'
import { graphql }            from 'gatsby'
import React, {
    FunctionComponent,
    useEffect,
    useState
}                             from 'react'
import CtaVideo               from '../components/blocks/cta-video'
import EnterpriseEnquiryForm  from '../components/blocks/EnterpriseEnquiryForm'
import PublicationEnquiryForm from '../components/blocks/PublicationEnquiryForm'
import StaffProfiles          from '../components/blocks/StaffProfiles'
import Testimonials           from '../components/blocks/Testimonials'
import WebinarVideoList       from '../components/blocks/WebinarVideoList'
import GraphQLErrorList       from '../components/errors/graphql-error-list'
import EventsList             from '../components/EventsList'
import ClrsInfograph          from '../components/one-to-100/clrs-infograph'
import PricingTable           from '../components/pricing-table/PricingTable'
import ProjectList            from '../components/projects/ProjectList'

import SEO from '../components/seo'

import {
    BottomWave,
    TopWave
}                  from '../components/wave'
import WebinarList from '../components/webinars/WebinarList'
import Layout      from '../containers/layout'

export const query = graphql`
    query PageTemplateQuery($id: String!) {
        route: sanityRoute(id: { eq: $id }) {
            slug {
                current
            }
            useSiteTitle
            page {
                ...PageInfo
            }
        }
        site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
            primaryColor {
                hex
            }
            secondaryColor {
                hex
            }
            title
            openGraph {
                title
                description
                image {
                    ...SanityImage
                }
            }
        }
    }
`

interface PageProps {
    data: {
        page: {
            id: string
            navMenu: unknown
            title: string
            _rawContent: unknown
        }
        site: {
            openGraph: unknown
            primaryColor: ColourData
            secondaryColor: ColourData
            title: string
        }
    }
    location: { search: string }
}

const Page: FunctionComponent<PageProps> = (props) => {
    const {data, errors} = props
    console.log('Page data: ', data)

    if (errors) {
        return (
            <Layout>
                <GraphQLErrorList errors={errors}/>
            </Layout>
        )
    }
    const [showAlert, setShowAlert] = useState<boolean>(false)

    const site = (data || {}).site

    if (!site) {
        throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
        )
    }

    useEffect(() => {
        if (props.location && props.location.search === '?fromfrom=true') {
            setShowAlert(true)
        }
    }, [props.location])

    const page = data.page || data.route.page

    const content = (page._rawContent || [])
        .filter((c: any) => !c.disabled)
        .map((c: any, i: number) => {
            let el = null
            // console.log('Component data:', c)
            switch (c._type) {
                case 'webinarVideoList': {
                    el = <WebinarVideoList data={c} key={c._key}/>
                    break
                }
                case 'titleBlock': {
                    el = <TitleBlock data={c}/>
                    break
                }
                case 'ctaVideo': {
                    el = <CtaVideo data={c}/>
                    break
                }
                case 'eventSelector': {
                    el = <EventsList key={c._key} data={c}/>
                    break
                }
                case 'featureList': {
                    el = <FeatureList featureListData={c}
                                      key={c._key}
                                      projectColour={'green'}
                                      theme={'blue|white|white|white'}/>
                    break
                }
                case 'pricingScheme': {
                    console.log('Pricing Scheme data: ', c)
                    el = <PricingTable type={'pricingScheme'} key={c._key} scheme={c}/>
                    break
                }
                case 'dealScheme': {
                    el = <PricingTable type={'dealScheme'} key={c._key} scheme={c}/>
                    break
                }
                case 'dealGroup': {
                    el = <PricingTable type={'dealGroup'} key={c._key} scheme={c}/>
                    break
                }
                case 'deal': {
                    el = <PricingTable type={'singleDeal'} key={c._key} scheme={c}/>
                    break
                }
                case 'callToAction': {
                    el = <CTA cta={c} key={c._key}/>
                    break
                }
                case 'testimonialComponent': {
                    el = <Testimonials testimonialComponentData={c} key={c._key}/>
                    break
                }
                case 'projectList': {
                    el = <ProjectList {...c} key={c._key}/>
                    break
                }
                case 'hero': {
                    el = <Hero key={c._key} node={c}/>
                    break
                }
                case 'richText': {
                    el = <RichText key={c._key} richTextComponentData={c} />
                    break
                }
                case 'staffProfiles': {
                    el = <StaffProfiles key={c._key} staffProfilesComponentData={c}/>
                    break
                }
                case 'webinarSelector': {
                    el = <WebinarList key={c._key} webinars={c.webinarList} heading={'Webinars'} blurb={''}/>
                    break
                }
                case 'uiComponentRef': {
                    switch (c.name) {
                        case 'topWave':
                            el = <TopWave/>
                            break
                        case 'bottomWave':
                            el = <BottomWave/>
                            break
                        case 'oneTo100':
                            el = <ClrsInfograph/>
                            break
                        case 'enterprise-enquiry-form':
                            el = <EnterpriseEnquiryForm/>
                            break
                        case 'publication-enquiry-form':
                            el = <PublicationEnquiryForm/>
                            break
                        default:
                            break
                    }
                    break
                }
                default: {
                    el = null
                }
            }
            return el
        })

    const gradient = {
        from: (site.primaryColor && site.primaryColor.hex) || '#D53369',
        to  : (site.secondaryColor && site.secondaryColor.hex) || '#DAAE51'
    }

    const menuItems = page.navMenu && (page.navMenu.items || [])
    const pageTitle = data.route && !data.route.useSiteTitle && page.title

    const handleDismissAlert = () => {
        // setShowAlert(false)
    }

    const renderAlert = () => (
        <section className={'p-8'}>
            <div className="rounded-md bg-blue-50 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                             fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd"
                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                  clip-rule="evenodd"/>
                        </svg>
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                        <p className="text-sm text-blue-700">
                            Thank you for your enquiry. We will be in touch soon.
                        </p>
                        <p className="mt-3 text-sm md:mt-0 md:ml-6">
                            <button onClick={() => handleDismissAlert()}
                                    className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">Dismiss <span
                                aria-hidden="true">&rarr;</span></button>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )

    return (
        <Layout navMenuItems={menuItems} textWhite={true}>
            <SEO
                title={pageTitle}
                description={site.description}
                keywords={site.keywords}
                bodyAttr={{
                    class: 'leading-normal tracking-normal gradient'
                }}
                gradient={gradient}
            />
            {
                showAlert && renderAlert()
            }
            <div className="min-h-screen">
                {content}
            </div>
        </Layout>
    )
}

export default Page
