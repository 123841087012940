import {
    MainImage,
    PortableTextRenderer
}                                   from '@open-law/open-law-shared'
import React, { FunctionComponent } from 'react'
import { utcTimeStringToDate }      from '../utilities/helpers'


interface RawChildren {
    _key: string
    _type: string
    marks: any[]
    text: string
}

interface Excerpt {
    _key: string
    _type: string
    style: string
    list?: any
    _rawChildren: RawChildren[]
}

export interface OpenLawEvent { // Called OpenLawEvent to avoid a collision with a type that is part of the DOM
    startDateTime: string;
    title: string;
    tags: string[];
    registrationUrl: string;
    platform: string;
    mainImage: MainImage;
    endDateTime: string;
    duration: number;
    publishedAt: string;
    excerpt: Excerpt[];

    body?: any
    slug: { current: string }
    // remember you can use the JSON to TS interface conversion tool.
}


interface EventListProps {
    data: { eventList: OpenLawEvent[] }
}


const EventsList: FunctionComponent<EventListProps> = (props) => {
    let x = 0
    let y = 0
    // console.log('Event List Props', props)

    const events: OpenLawEvent[] = props.data.eventList

    const renderListItem = (event: OpenLawEvent) => (
        <a href={event.registrationUrl} className="pb-5 w-full">
            <div className="relative shadow-lg pt-6 pb-10 px-6 rounded-lg md:px-6 md:pt-14 md:pb-14 md:px-14">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="block mt-2 bg-center bg-contain bg-no-repeat h-0 w-full "
                         style={{
                             backgroundImage: `url(${event.mainImage ? event.mainImage.asset.url : ''})`,
                             paddingTop     : event.mainImage.asset.metadata.dimensions.height / event.mainImage.asset.metadata.dimensions.width * 500
                         }}>
                    </div>
                    <div>
                        <p className="text-5xl pb-6 text-black">
                            {event.title}
                        </p>
                        <div>
                            <PortableTextRenderer projectColour={'blue'} blocks={event.excerpt}/>
                        </div>
                        <div className=" grid grid-cols-2 pb-4 gap-4">
                            <a
                                href={`/events/${event.slug.current}`}
                                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
                                Details
                            </a>
                            <a href={event.registrationUrl}
                               className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
                                Register
                            </a>
                        </div>

                    </div>
                </div>
                <div className="grid grid-cols-3 pt-4 gap-4">
                    <p className="mt-xl max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                        {
                            utcTimeStringToDate(event.startDateTime)
                        }
                    </p>
                    <p className="mt-xl max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                        {event.duration} hour
                    </p>
                </div>
            </div>


        </a>
    )

    const renderPastEvent = (event: OpenLawEvent) => {
        // return (<p>{Date.parse(event.startDateTime)} {`${new Date(event.startDateTime).getTime()}
        // ${Date.now()}`}</p>)
        if (new Date(event.startDateTime).getTime() < Date.now() && x < 3) {
            // return (<p> {JSON.stringify(event, null, 4)}</p>)
            x = x + 1
            return (
                <section className="flex justify-center ml-6">
                    <section className="w-full flex gap-4 mb-8 border-2 border-gray-200 shadow rounded p-6 -ml-6">
                        <div className="w-full sm:w-2/3 mr-6">
                            <div className="mb-2">
                                <h2 className="text-2xl text-black font-medium">
                                    {event.title}
                                </h2>
                                <p className="text">
                                    Event date: {utcTimeStringToDate(event.startDateTime)}
                                </p>
                            </div>

                            <div className="flex flex-row -ml-6 pl-6">
                                <a className="px-4 py-2 bg-blue-500 text-white text-sm rounded"
                                   href={`/events/${event.slug.current}`}>
                                    Read More
                                </a>
                            </div>
                        </div>
                        <div className="w-1/3 justify-center items-center hidden sm:flex">
                            <img className="" src={event.mainImage.asset.url} alt={event.mainImage.alt}/>
                        </div>
                    </section>
                </section>
            )
        }
    }

    const renderSmallPastEvent = (event: OpenLawEvent) => {
        if (new Date(event.startDateTime).getTime() < Date.now() && y > 2) {
            return (
                <a href={`/events/${event.slug.current}`}>
                    <section className="flex justify-center ml-6">
                        <section
                            className="w-full flex gap-4 mb-8 border-2 border-gray-200 shadow rounded px-6 pt-3 -ml-6">
                            <div className="w-full sm:w-2/3 mr-6">
                                <div>
                                    <h2 className="text-2xl text-black font-medium">
                                        {event.title}
                                    </h2>
                                </div>

                                {/*<div className="flex flex-row -ml-6 pl-6">*/}
                                {/*    <a className="px-4 py-2 bg-blue-500 text-white text-sm rounded"*/}
                                {/*       href={`/events/${event.slug.current}`}>*/}
                                {/*        Read More*/}
                                {/*    </a>*/}
                                {/*</div>*/}
                            </div>
                            <div className="w-1/3 justify-center items-center hidden sm:flex">
                                <p className="text font-normal">
                                    Event date: {utcTimeStringToDate(event.startDateTime)}
                                </p>
                                {/*<img className="" src={event.mainImage.asset.url} alt={event.mainImage.alt}/>*/}
                            </div>
                        </section>
                    </section>
                </a>
            )
        } else if (new Date(event.startDateTime).getTime() < Date.now() && y <= 2) {
            y = y + 1
            return null
        }
    }

    const renderEventItem = (event: OpenLawEvent) => {
        if (Date.parse(event.startDateTime) > Date.now()) {
            return (
                <section className="flex justify-center ml-6">
                    <section className="w-full flex gap-4 mb-8 border-2 border-gray-200 shadow rounded p-6 -ml-6">
                        <div className="w-full sm:w-2/3 mr-6">
                            <div className="mb-2">
                                <h2 className="text-2xl text-black font-medium">
                                    {event.title}
                                </h2>
                                <p className="text">
                                    Event date: {utcTimeStringToDate(event.startDateTime)}
                                </p>
                            </div>
                            <div className="flex flex-row -ml-6">
                                <a className="px-4 py-2 bg-blue-500 text-white text-sm rounded ml-6 mr-6"
                                   href={event.registrationUrl}>
                                    Register
                                </a>
                                <a className="px-4 py-2 bg-blue-500 text-white text-sm rounded"
                                   href={`/events/${event.slug.current}`}>
                                    Read More
                                </a>
                            </div>
                        </div>
                        <div className="w-1/3 justify-center items-center hidden sm:flex">
                            <img className="" src={event.mainImage.asset.url} alt={event.mainImage.alt}/>
                        </div>
                    </section>
                </section>
            )
        }
    }

    return (
        <section className="relative mx-auto max-w-7xl">
            {
                events &&
                events.length >= 1 ? (
                    <section>
                        {
                            events.map((event) => event.publishedAt ? renderEventItem(event) : null)
                        }
                        {
                            <h2 className="text-4xl text-black text-center font-semibold pb-6">
                                Past Events
                            </h2>
                        }
                        {
                            events.map((event) => renderPastEvent(event))
                        }
                        {
                            x >= 2 ? (
                                events.map((event) => renderSmallPastEvent(event))
                            ) : null
                        }
                    </section>
                ) : (
                    <p>
                        No events data present.
                    </p>
                )
            }
        </section>
    )
}

export default EventsList






