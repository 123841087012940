import React, {FunctionComponent}     from 'react'
import {Author, Webinar, WebinarPart} from '@open-law/open-law-shared'

interface ProjectListProps {
    webinars: Webinar[]
}

const WebinarList: FunctionComponent<ProjectListProps> = (props) => {
    // console.log('Webinar List props: ', props)
    const webinarData = props.webinars
    const renderProjectList = (webinar: Webinar) => {

        return (
            <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
                <div className="absolute inset-0">
                    <div className="bg-white h-1/3 sm:h-2/3"></div>
                </div>
                <div className="relative max-w-7xl mx-auto">
                    <div className="text-center">
                        <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                            {webinar.title}
                        </h2>
                        {
                            webinar.blurb && webinar.blurb.split('\n').map((line, idx) => (
                                <p key={idx} className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                                    {line}
                                </p>
                            ))
                        }
                    </div>
                    <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                        {
                            webinar && webinar.parts.map((project) => project.video && project.video.videoUrl ? renderProjectItem(webinar, project) : null)
                        }
                    </div>
                </div>
            </div>
        )
    }

    const buttonStyles = `text-base font-semibold text-blue-600 hover:text-blue-500 mt-3 `

    const renderProjectItem = (webinar: Webinar, part: WebinarPart) => {
        // console.log(part, webinar)
        return (
            <div key={part.title}
                 className="group flex flex-col rounded-lg shadow-lg ">
                <a href={`/webinars/${webinar.slug.current}/${part && part.slug && part.slug.current ? part.slug.current : ''}`}
                   className="block mt-2">
                    <div className="">
                        {
                            part.thumbnail?.asset?.url ? (
                                <img className="h-48 w-full object-cover"
                                     src={part.thumbnail ? part.thumbnail.asset.url : ''}
                                />
                            ) : null

                        }
                    </div>
                </a>

                <div className="group-hover:bg-gray-100 flex-1 bg-white p-6 flex flex-col justify-between">

                    <div className="flex-1 flex justify-end ">
                        <a href={`/webinars/${webinar.slug.current}/${part && part.slug && part.slug.current ? part.slug.current : ''}`}
                           className="block mt-2">
                            <p className="text-xl font-semibold text-gray-900">
                                {part.title}
                            </p>
                            <p className="mt-3 text-base text-gray-500">
                                {
                                    part.partBlurb && part.partBlurb.split('\n').map((line, idx) => (
                                        <p key={idx} className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                                            {line}
                                        </p>
                                    ))
                                }
                            </p>
                        </a>
                    </div>
                    {
                        webinar.authors && webinar.authors.map((author) => renderProjectLead(author))
                    }
                </div>
            </div>
        )
    }

    const renderProjectLead = (projectLead: Author) => (
        <>
            <div className="mt-3 flex">
                <p className="text-sm font-medium text-gray-500">
                    Instructor
                </p>
            </div>
            <div className="mt-2 flex items-center">
                <div className="flex-shrink-0">
                    <a href="#">
                        <span className="sr-only">{projectLead.name}</span>

                        <img className="h-10 w-10 rounded-full"
                             src={projectLead.image.asset.url}
                             alt=""/>
                    </a>
                </div>
                <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                        <a href="#" className="hover:underline">
                            {projectLead.name}
                        </a>
                    </p>
                </div>
            </div>
        </>
    )
    return (
        <section>
            {
                webinarData && webinarData.map((webinar) => renderProjectList(webinar))
            }
        </section>
    )
}

export default WebinarList

